<template>
  <van-list v-model:loading="_loading" class="list" :offset="10" :finished="finished" :finished-text="$t('common.desc14')"
    :loading-text="$t('common.desc15')" @load="emit('load')">
    <slot />
  </van-list>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
  loading: Boolean,
  finished: Boolean,
});

const emit = defineEmits(["update:loading", "load"]);

const _loading = computed({
  get() {
    return props.loading;
  },
  set(e) {
    emit("update:loading", e);
  },
});
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}
</style>
