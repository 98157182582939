export default function directive(app) {
  //只能输入数字
  app.directive("LimitInputNumber", {
    mounted(el) {
      el.oninput = () => {
        el.value = el.value.replace(/[^\d.]/g, "");
      };
    },
  });

  //密码
  app.directive("password", {
    mounted(el) {
      el.addEventListener("input", function (e) {
        let reg = new RegExp("^[A-Za-z0-9]*$");
        if (!reg.test(el.value)) {
          el.value = el.value.replace(/[^A-Za-z0-9]+/g, "");
          el.dispatchEvent(new Event("input")); //调用input事件使vue v-model绑定更新
        }
      });
    },
  });

  app.directive("NoSpace", {
    mounted(el) {
      let prevValue = el.value;

      el.addEventListener("input", function (e) {
        const newValue = el.value.replace(/\s/g, "");
        if (el.value !== newValue) {
          el.value = newValue;
          el.dispatchEvent(new Event("input"));
        }
      });
    },
  });

  app.directive("number-with-decimal", {
    beforeMount(el, binding) {
      const regex = /^[0-9]+(\.[0-9]{0,4})?$/; // 匹配最多四位小数的数字

      function isValidInput(value) {
        if (!regex.test(value)) {
          // 如果输入不符合规则，则移除非法字符
          value = value.replace(/[^\d.]/g, "");
        }

        // 获取小数点的位置
        const decimalIndex = value.indexOf(".");
        if (decimalIndex !== -1) {
          // 限制小数部分最多为四位
          const decimalPart = value.slice(decimalIndex + 1, decimalIndex + 5);
          value = value.slice(0, decimalIndex + 1) + decimalPart;
        }

        return value;
      }

      el.addEventListener("input", (event) => {
        event.target.value = isValidInput(event.target.value);
      });
      el.addEventListener("blur", (event) => {
        // 失去焦点时检查小数点之后是否没有数字，并在需要时移除小数点
        const value = event.target.value;
        const decimalIndex = value.indexOf(".");
        if (decimalIndex !== -1 && decimalIndex === value.length - 1) {
          // 如果小数点在最后一位，说明小数点之后没有数字，移除小数点
          event.target.value = value.slice(0, decimalIndex);
        }
        event.target.value = isValidInput(event.target.value);
      });
    },
  });

  app.directive("NumberOnly", {
    mounted(el) {
      el.addEventListener("input", function (event) {
        const input = event.target;
        const regex = /^[0-9]*$/; // 正则表达式，匹配正整数
        if (!regex.test(input.value)) {
          // 如果输入不是正整数，则清除非法字符
          input.value = input.value.replace(/[^0-9]/g, "");
          el.dispatchEvent(new Event("input")); //调用input事件使vue v-model绑定更新
        }
      });
    },
  });
}
