import PageNav from "./PageNav";
import EmailVerificationPage from "./EmailVerificationPage";
import CopyBtn from "./CopyBtn";
import ComImage from "./ComImage";
import ComList from "./ComList";
import GoogleCodeBindToastPopup from "./GoogleCodeBindToastPopup";

const components = {
  PageNav,
  EmailVerificationPage,
  CopyBtn,
  ComImage,
  ComList,
  GoogleCodeBindToastPopup,
};

export default {
  install(app) {
    Object.keys(components).forEach((key) => {
      app.component(key, components[key]);
    });
  },
};
