<template>
  <div class="page">
    <PageHeader />
    <div class="page-content">
      <slot />
    </div>

    <AuthorizedPopup />
    <GoogleCodeBindToastPopup />
  </div>
</template>

<script setup>
import AuthorizedPopup from "../AuthorizedPopup";
import PageHeader from "../PageHeader";
import { ref, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { proxy } = getCurrentInstance();
const store = useStore();

function handleLoadinit() {
  handleIsMobile();
  window.onresize = () => {
    handleIsMobile();
  };
}
function handleIsMobile() {
  let width = document.documentElement.clientWidth;
  if (width <= 768) {
    store.commit("SET_IS_MOBILE", true);
  } else {
    store.commit("SET_IS_MOBILE", proxy.common.isMobile());
  }
}
onMounted(() => {
  handleLoadinit();
});
</script>

<style lang="less" scoped>
.page {
  background-color: #fff;
}

.page-content {
  position: relative;
}
</style>
