import { createStore } from "vuex";
import common from "./modules/common";
import login from "./modules/login";
import google from "./modules/google";
import withdrawal from "./modules/withdrawal";
import transfer from "./modules/transfer";

export default createStore({
  state: {
    ...common.state,
    ...login.state,
    ...google.state,
    ...withdrawal.state,
    ...transfer.state,
  },
  mutations: {
    ...common.mutations,
    ...login.mutations,
    ...google.mutations,
    ...withdrawal.mutations,
    ...transfer.mutations,
  },

  actions: {
    ...common.actions,
    ...login.actions,
    ...google.actions,
    ...withdrawal.actions,
    ...transfer.actions,
  },
  getters: {
    ...common.getters,
    ...login.getters,
    ...google.getters,
    ...withdrawal.getters,
    ...transfer.getters,
  },
  modules: {},
});
