let tHistoryData = {};
let transferData = {};
try {
  if (localStorage.tHistoryData) {
    tHistoryData = JSON.parse(localStorage.tHistoryData);
  }
  if (localStorage.transferData) {
    transferData = JSON.parse(localStorage.transferData);
  }
} catch (error) {}

export default {
  state: {
    transferData,
    tHistoryData,
  },
  mutations: {
    SET_THISTORY_DATA(state, data) {
      state.tHistoryData = Object.assign(state.tHistoryData, data);
      localStorage.tHistoryData = JSON.stringify(data);
    },
    SET_TRANSFER_DATA(state, data) {
      state.transferData = data;
      localStorage.transferData = JSON.stringify(data);
    },
  },

  actions: {},
  getters: {},
};
