import config from "../config";
const { post, get, URL, Toast, $t } = config;

export default {
  assetList() {
    return post(URL.asset.list, {});
  },
  assetdetail(data) {
    return post(URL.asset.detail, data);
  },
  getWithdrawConfig(data) {
    return post(URL.asset.withdrawConfig, data);
  },
  postWithdraw(data) {
    return post(URL.asset.withdraw, data);
  },
  getWithdrawRecord(data) {
    return post(URL.asset.withdrawRecord, data);
  },
  getTransferConfig(data) {
    return post(URL.asset.transferConfig, data);
  },
  postTransfer(data) {
    return post(URL.asset.transfer, data);
  },
  getTransferRecord(data) {
    return post(URL.asset.transferRecord, data);
  },
  getTransferDetail(data) {
    return post(URL.asset.transferDetail, data);
  },
  transferCal(data) {
    return post(URL.asset.transferCal, data);
  },
};
