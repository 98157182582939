<template>
    <div class="copy-btn" @click="handleCopy" />
</template>

<script setup >
import useClipboard from 'vue-clipboard3'
import { getCurrentInstance } from 'vue';
const props = defineProps({
    msg: String
})

const { proxy } = getCurrentInstance()
const { toClipboard } = useClipboard()

async function handleCopy() {
    await toClipboard(props.msg)
    proxy.$toast(proxy.$t('common.desc1'))
}

</script>

<style scoped lang='less'>
.copy-btn {
    display: inline-block;
    flex: 0 0 auto;
    width: 0.22rem;
    height: 0.22rem;
    background: url('../../public/img/icon_copy.svg') no-repeat center;
    background-size: contain;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .copy-btn {
        width: 0.16rem;
        height: 0.16rem;
    }
}
</style>
