export default {
  common: {
    sendVerifyCode: "/api/verify/code/send",
  },
  login: {
    accountLogin: "/api/user/login",
    forgetPwd: "/api/user/password/login/forget",
    logOut: "/api/user/logout",
    getUserFindEmail: "/api/user/email",
    userInfo: "/api/user/info/personal",
    updatePassword: "/api/user/password/update",
    getHvUrl: "/api/index/hv/url",
    postHvLogin: "/api/user/hv/login",
    setPassword: "/api/user/password/set",
    checkCode: "/api/verify/code/check",
    getAccount:'/api/user/getEmailByAccount',//获取账号和邮箱
    verifyEmail:'/api/user/verifyEmailCode',//校验验证码
    setPsd:'/api/user/setPassword',//设置hv密码
  },
  google: {
    getGoogleurl: "/api/user/google/random",
    setGoogle: "/api/user/google/set",
    updateGoogle: "/api/user/google/update",
  },
  asset: {
    list: "/api/wallet/asset/list",
    detail: "/api/wallet/asset",
    withdrawConfig: "/api/wallet/withdraw/config",
    withdrawRecord: "/api/wallet/withdraw/record",
    withdraw: "/api/wallet/withdraw",
    transferConfig: "/api/wallet/transfer/config",
    transferRecord: "/api/wallet/transfer/dv/transfer/record",
    transfer: "/api/wallet/transfer/dv/transfer",
    transferDetail: "/api/wallet/transfer/dv/transfer/info",
    transferCal: "/api/wallet/transfer/dv/transfer/cal",
  },
};
