export default {
  state: {
    isMobile: false,
    safetyType: "withdrawal", //transfer
    safetyShow: false,
    isVerifyEmailCode: false,
  },
  mutations: {
    SET_IS_MOBILE(state, isMobile) {
      state.isMobile = isMobile;
    },
    SET_SAFETY_TYPE(state, type) {
      state.safetyType = type;
    },
    SET_SAFETY_SHOW(state, show) {
      state.safetyShow = show;
    },
    SET_IS_VERIFY_EMAIL_CODE(state, bool) {
      state.isVerifyEmailCode = bool;
    },
  },

  actions: {},
  getters: {},
};
