let wHistoryData = {};
try {
  if (localStorage.wHistoryData) {
    wHistoryData = JSON.parse(localStorage.wHistoryData);
  }
} catch (error) {}

export default {
  state: {
    withdrawalData: {},
    wHistoryData,
  },
  mutations: {
    SET_WHISTORY_DATA(state, data) {
      state.wHistoryData = Object.assign(state.wHistoryData, data);
      localStorage.wHistoryData = JSON.stringify(data);
    },
    SET_WITHDRAWAL_DATA(state, data) {
      state.withdrawalData = data;
    },
  },

  actions: {},
  getters: {},
};
