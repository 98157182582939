import axios from "axios";
import router from "@/router";
import store from "@/store";
import common from "./common";
import { Toast } from "vant";
axios.defaults.timeout = 15000; //请求超时5秒
axios.defaults.baseURL = process.env.VUE_APP_URL; //请求base url
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded"; //设置post请求是的header信息
import i18n from "@/public/lang/i18n";
//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    let token =
      localStorage.getItem("token") == null
        ? ""
        : localStorage.getItem("token");
    let lang = localStorage.getItem("language") || "zh_TW";
    let timestamp = new Date().getTime();
    if (config.isUpload) {
      var obj = new Object();
    } else {
      var obj = Object.assign({}, config.data);
    }
    obj.timestamp = timestamp;
    obj.apiKey = "test";
    if (process.env.NODE_ENV == "production") {
      obj.apiKey = "CnuKWWNIIkjTD5P";
    }
    // 去掉签名对象里面的空值，未定义字段
    for (var key in obj) {
      if (obj[key] === "" || undefined === obj[key] || null === obj[key]) {
        delete obj[key];
      }
    }
    obj.sign = common.sign(obj);
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      // 判断是否是upload模式，如果是则需要改为form-data格式

      if (config.isUpload) {
        let formData = new FormData(); //new一个formData事件
        formData.append("file", config.data.file);
        formData.append("sign", obj.sign);
        formData.append("timestamp", obj.timestamp);
        formData.append("apiKey", obj.apiKey);
        config.data = formData;
        config.headers = {
          "Content-Type": "multipart/form-data",
          system: "h5",
          token,
          lang,
        };
      } else {
        config.data = obj;
        config.data = JSON.stringify(config.data);
        config.headers = {
          "Content-Type": "application/json",
          system: "h5",
          token,
          lang,
        };
      }
    } else {
      config.data = obj;
      config.headers = {
        "Content-Type": "application/json",
        system: "h5",
        lang,
      };
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log({
      response,
    });
    let { code, message, msg } = response.data;
    if (code == 0) {
      return Promise.resolve(response.data);
    } else if (code === 80005) {
      store.commit("SET_AUTHORIZED_SHOW", true);
      return Promise.reject(response);
    } else if (code == 401) {
      Toast({
        message,
        duration: 3000,
      });
      router.replace("/login");
      store.commit("SET_LOG_OUT");
      let timer = setTimeout(() => {
        timer = null;
        Toast.clear();
      }, 3000);
    } else {
      Toast({
        message,
        duration: 3000,
      });
      let timer = setTimeout(() => {
        timer = null;
        Toast.clear();
      }, 3000);
      return Promise.reject(response);
    }
  },
  (err) => {
    console.log({ err });
    if (err.code === "ECONNABORTED" && err.message.indexOf("timeout") !== -1) {
      Toast({
        message: `${i18n.global.t("common.desc4")}`,
        duration: 3000,
      });
      let timer = setTimeout(() => {
        timer = null;
        Toast.clear();
      }, 3000);
    }
    return Promise.reject(err);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(
  url,
  data = {},
  config = {
    isUpload: false,
  }
) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
