import store from '@/store'
import config from '../config'
const { post, get, URL, Toast, $t } = config

export default {
    getUserInfo() {
        return post(URL.login.userInfo)
    },
    getUserFindEmail(data) {
        return post(URL.login.getUserFindEmail, data)
    },
    async accountLogin(data) {
        const res = await post(URL.login.accountLogin, data)
        store.commit('SET_USER_DATA', res)
    },

    async postHvLogin(data) {
        const res = await post(URL.login.postHvLogin, data)
        store.commit('SET_USER_DATA', res)
        return res
    },
    async logOut() {
        await post(URL.login.logOut)
        store.commit('SET_LOG_OUT')
        Toast({
            message: $t('common.desc9'),
            duration: 3000
        })
    },
    updatePassword(data) {
        return post(URL.login.updatePassword, data)
    },
    getHvUrl() {
        return post(URL.login.getHvUrl)
    },
    setPassword(data) {
        return post(URL.login.setPassword, data)
    },
    checkCode(data) {
        return post(URL.login.checkCode, data)
    }
}