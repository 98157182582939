<template>
    <van-popover v-model:show="show" placement="bottom-end">
        <template #reference>
            <img class="icon-lang" src="../../../public/img/icon_lang.svg" />
        </template>
        <div class="content">
            <p>{{ $t('header.desc5') }}</p>
            <div class="list">
                <div class="item" :class="{ active: langActive === 'en_US' }" @click="handleSwichLang('en_US')">
                    <span>English</span>
                    <van-icon class="icon" name="success" />
                </div>
                <!-- <div class="item" :class="{ active: langActive === 'zh_TW' }" @click="handleSwichLang('zh_TW')">
                    <span>中文繁體</span>
                    <van-icon class="icon" name="success" />
                </div> -->
            </div>
        </div>
    </van-popover>
</template>

<script setup >
import zhTw from "vant/lib/locale/lang/zh-TW";
import enUS from 'vant/es/locale/lang/en-US';
import { Locale } from 'vant'
import { ref, getCurrentInstance, onMounted } from 'vue';
const { proxy } = getCurrentInstance()
let show = ref(false)
const langActive = ref('en_US')
function handleSwichLang(lang) {
    proxy.$i18n.locale = lang
    if (lang === 'en_US') {
        Locale.use("en-US", enUS);
    } else {
        Locale.use("zh-TW", zhTw);
    }
    langActive.value = lang
    localStorage.language = lang
    show.value = false
}

onMounted(() => {
    try {
        if (langActive.value) {
            langActive.value = localStorage.language
        }
    } catch (error) { }
})
</script>

<style scoped lang='less'>
.icon-lang {
    width: 0.24rem;
    height: 0.26rem;
    object-fit: contain;
    cursor: pointer;
}

.content {
    padding: 0.22rem 0.16rem;

    p {
        color: #BDBDBD;
        font-family: Poppins;
        font-size: 0.12rem;
        line-height: 1.2em;
        font-style: normal;
        font-weight: 400;
    }

    .list {
        display: flex;
        flex-direction: column;

        .item {
            min-width: 1.28rem;
            margin-top: 0.08rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.08rem;
            box-sizing: border-box;
            border-radius: 0.04rem;
            cursor: pointer;
            transition: all 0.3s linear;

            &.active,
            &:hover {
                background: #F1F1F1;

                span {
                    color: #8644FF;
                }

                .icon {
                    display: block;
                }
            }




            span {
                color: #000;
                font-family: Poppins;
                font-size: 0.14rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.4em;
                transition: all 0.3s linear;
            }

            .icon {
                margin-left: 0.08rem;
                display: none;
                color: #8644FF;
            }
        }
    }
}

@media screen and (max-width: 768px) {


    .icon-lang {
        width: 0.18rem;
        height: 0.18rem;
    }
}
</style>
