import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/asset",
      redirect: "/asset/info",
      meta: {
        title: "title.asset",
        role: "user",
      },
      component: () => import("@/views/asset"),
      children: [
        {
          path: "info",
          meta: {
            title: "title.asset",
            role: "user",
          },
          component: () => import("@/views/asset/asset-info"),
        },
        {
          path: "withdrawal",
          meta: {
            title: "title.asset",
            role: "user",
          },
          component: () => import("@/views/asset/withdrawal"),
        },
        {
          path: "withdrawal-history",
          meta: {
            title: "title.asset",
            role: "user",
          },
          component: () => import("@/views/asset/withdrawal-history"),
        },
        {
          path: "withdrawal-detail",
          meta: {
            title: "title.asset",
            role: "user",
          },
          component: () => import("@/views/asset/withdrawal-history/detail"),
        },
        {
          path: "transfer",
          meta: {
            title: "title.asset",
            role: "user",
          },
          component: () => import("@/views/asset/transfer"),
        },
        {
          path: "transfer-history",
          meta: {
            title: "title.asset",
            role: "user",
          },
          component: () => import("@/views/asset/transfer-history"),
        },
        {
          path: "transfer-detail",
          meta: {
            title: "title.asset",
            role: "user",
          },
          component: () => import("@/views/asset/transfer-history/detail"),
        },
      ],
    },
    {
      path: "/login",
      meta: {
        title: "title.login",
        role: "guest",
      },
      component: () => import("@/views/login"),
    },
    {
      path: "/authorized-set",
      meta: {
        title: "title.authorizedSet",
        role: "guest",
      },
      component: () => import("@/views/login/authorized-set"),
    },
    {
      path: "/hvset",
      meta: {
        title: "title.authorizedSet",
        role: "guest",
      },
      component: () => import("@/views/login/hvset"),
    },
    {
      path: "/sethvpasd",
      meta: {
        title: "login.desc67",
        role: "guest",
      },
      component: () => import("@/views/login/setpasd"),
    },
    {
      path: "/hvregister",
      meta: {
        title: "login.desc67",
        role: "guest",
      },
      component: () => import("@/views/login/firstname"),
    },
    {
      path: "/authorized-login",
      meta: {
        title: "title.authorizedlogin",
        role: "guest",
      },
      component: () => import("@/views/login/authorized-login"),
    },
    {
      path: "/login-verification",
      meta: {
        title: "title.emailVerification",
        role: "guest",
      },
      component: () => import("@/views/login/login-verification"),
    },
    {
      path: "/forgot",
      redirect: "/forgot/username",
      meta: {
        title: "title.forgot",
        role: "guest",
      },
      component: () => import("@/views/forgot"),
      children: [
        {
          path: "username",
          meta: {
            title: "title.forgot",
            role: "guest",
          },
          component: () => import("@/views/forgot/username"),
        },
        {
          path: "set",
          meta: {
            title: "title.forgot",
            role: "guest",
          },
          component: () => import("@/views/forgot/set"),
        },
      ],
    },
    {
      path: "/forgot-verification",
      meta: {
        title: "title.emailVerification",
        role: "guest",
      },
      component: () => import("@/views/forgot/verificatio"),
    },
    {
      path: "/google-change",
      meta: {
        title: "title.googleChange",
        role: "user",
      },
      component: () => import("@/views/google/change"),
    },
    {
      path: "/google",
      meta: {
        title: "title.googleSet",
        role: "user",
      },
      redirect: "/google/set",
      component: () => import("@/views/google"),
      children: [
        {
          path: "email",
          meta: {
            title: "title.googleSet",
            role: "user",
          },
          component: () => import("@/views/google/email"),
        },
        {
          path: "set",
          meta: {
            title: "title.googleSet",
            role: "user",
          },
          component: () => import("@/views/google/set"),
        },
      ],
    },
    {
      //路由重定向
      path: "/:pathMatch(.*)*",
      redirect: "/login",
    },
  ],
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});
router.beforeEach((to, from, next) => {
  const isLogin = store.getters.isLogin;
  if (to.meta.role === "user" && !isLogin) {
    next((vm) => {
      vm.$router.replace("/login");
    });
    return;
  }
  next();
});
export default router;
