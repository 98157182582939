const en = {
  title: {
    asset: "Asset",
    login: "Login",
    authorizedSet: "Safety Verification",
    emailVerification: "Email verification code",
    forgot: "Forgot your password",
    googleSet: "Set Google Authenticator Code",
    googleChange: "Changing Google Authentication",
  },
  header: {
    desc1: "personal center",
    desc2: "Change Google",
    desc3: "Change Password",
    desc4: "Log out",
    desc5: "Language",
    desc6: "English",
    desc7: "Traditional Chinese",
    desc8: "Set GA Code",
    desc9: "Help Center",
  },
  common: {
    desc1: "Copied",
    desc2: "Cancel",
    desc3: "Confirm",
    desc4: "Network exception, connection timed out...",
    desc5: "Please fill in the complete verification code",
    desc6: "The two passwords do not match",
    desc7: "Successfully",
    desc8: "Email verification code sent successfully",
    desc9: "Log out successfully",
    desc10: "HV Users",
    desc11: "HN Users",
    desc12: "Successfully",
    desc13: "Withdrawal application is successful",
    desc14: "No More",
    desc15: "loading...",
    desc16: "Login Success",
    desc17: "Verification code error",
    desc18: "Transfer application successful",
    desc19: "Enable now",
  },
  login: {
    desc1: "HyperCommunity Asset Management Platform",
    desc2: "Login to your account",
    desc3: "Username/Email",
    desc4: "Please enter Username/Email",
    desc5: "Password",
    desc6: "Please enter login password",
    desc7: "Forgot your password?",
    desc8: "Confirm",
    desc9: "HyperVerse First Time Login",
    desc10: "Safety Verification",
    desc11:
      "Please reset password on first login for enhanced security. Use the new password with your username for future logins in HyperCommunity.",
    desc12: "Set new password",
    desc13: "8-20 characters",
    desc14: "Confirm password",
    desc15: "Confirm new password",
    desc16: "Email Verification Code",
    desc17: "Email verification code has been sent to ：",
    desc18: "Enter email verification code",
    desc19: "Send",
    desc21: "Resend",
    desc22: "Confirm",
    desc23: "Forgot your password",
    desc24:
      "Notice: Withdrawals are not available within 24 hours after the successful change.",
    desc25: "Next",
    desc26: "Email",
    desc27: `Please download 'Google Authenticator' from the app store`,
    desc28: "Scan or copy secret key",
    desc29: "Scan the QR code to bind with Google Authenticator",
    desc30: "Enter the Google Authentication code to complete authentication",
    desc31: "Google Verify Code",
    desc32: "Please enter the Google Authentication Code",
    desc33: "Please enter the 6-digit verification code of {n}",
    desc34: "Tips",
    desc35: `1. If you can't scan the QR code, you can add your account with the following key:`,
    desc36: `2. Please refrain from deleting this account as it may prevent you from performing account operations.`,
    desc37:
      "Notice: Withdrawals are not available within 24 hours after the successful change.",
    desc38: "Enter email verification code",
    desc39: "Old Google Verify",
    desc40: "Please enter the old Google Authentication code",
    desc41:
      "Please enter the 6-digit verification code for the old Google Verify",
    desc42: 'Use "Google Authentication" to scan the QR code below',
    desc43:
      "Please record the key and keep it in a safe place. You can use this key to restore your Google authentication",
    desc44: "Enter the Google Authentication code to complete authentication",
    desc45: "Please enter the 6-dight GA code",
    desc46: "Proceed to logout?",
    desc47: "Current Password",
    desc48: "New Password",
    desc49: "Confirm the new password",
    desc50: "Please enter Current Password",
    desc51:
      "Password within 8-20 characters contains uppercase and lowercase letter",
    desc52: "Please enter new password again",
    desc53: "Daoversal Authorization",
    desc54:
      "Daoversal requests to access your UID, Email, Username and Account information.",
    desc55: "Cancel",
    desc56: "Authorize",
    desc57:
      "Your HyperVerse account is not logged into HyperCommunity. Please log in to HyperVerse for authorization to proceed",
    desc58: "Proceed to HyperVerse",
    desc59: "Switch Account",
    desc60: "Enter Current GA Code",
    desc61:'For account security, please verify your HyperVerse account as this marks the first login to the HyperCommunity platform.',
    desc62:'Cancel',
    desc63:'Proceed to Verification',
    desc64:'Complete the email verification to log in to HyperCommunity.',
    desc65:'HyperVerse Username',
    desc66:'HyperVerse Email',
    desc67:'Set Account Password',
    desc68:'Please set a new HyperCommunity password and use the new password along with the HyperVerse username for the next login.',
    desc69:'Set New Password',
    desc70:'Password within 8-20 characters contains uppercase and lowercase letter',
    desc71:'Confirm Password',
    desc72:'Confirm New password',
    desc73:'HyperVerse Account Verification',
    desc74:'For first-time HyperVerse login users, access your HyperCommunity account by completing the email verification process.',
    desc75:'Enter HyperVerse Username',
    desc76:'HyperVerse username does not exists',
    desc77:'This account has been logged into HyperCommunity before, please use the HyperCommunity login credentials to login.'
  },
  asset: {
    desc1: "UID",
    desc2: "Username",
    desc3: "Mail",
    desc4: "Last Login",
    desc5: "IP address",
    desc6: "Source",
    desc7: "Total Assets(HPT)",
    desc8: "Pending Rewards",
    desc9: "Token",
    desc10: "Total",
    desc11: "Available",
    desc12: "Frozen",
    desc13: "Function",
    desc14: "Withdrawal",
    desc15: "Withdrawal History",
    desc16: "Withdrawal",
    desc17: "Coin",
    desc18: "Chain Name",
    desc19: "Address",
    desc20: "Enter withdrawal address",
    desc21: "Amount",
    desc22: "Enter the withdrawal amount",
    desc23: "ALL",
    desc24: "Available Balance: ",
    desc25: "Fee",
    desc26: "Actual Received",
    desc27: "Withdraw",
    desc28:
      "Only withdraw the token to the provided address. Depositing any other digital assets may lead to permanent loss of your funds. ",
    desc29: "Minimum withdrawal amount: {n} HPT",
    desc30:
      "Your withdrawal address will remain unchanged. If there are any changes, we will notify you through announcements or email.",
    desc31: `Ensure that the selected network matches the platform's network for both withdrawals and deposits.Using the wrong network can result in the loss of your funds.`,
    desc32:
      "Digital assets purchased on fiat are prohibited from withdrawal for 24 hours",
    desc33:
      "USDT does not support smart contract withdrawals at this time, please understand",
    desc34: "Order ID",
    desc35: "Time",
    desc36: "Withdrawal address",
    desc37: "Transaction ID",
    desc38: "Token",
    desc39: "Quantity",
    desc40: "Mainnet",
    desc41: "Fees",
    desc42: "Status",
    desc43: "No record",
    desc44: "Enter the 6-dight email code",
    desc45: "Google Verification Code",
    desc46: "Enter the 6-digit Google verification code",
    desc47:
      "No other operations can be performed on the assets to be released, and the asset usage plan will be updated later",
    desc48: "Pending",
    desc49: "withdrawing",
    desc50: "Success",
    desc51: "Refunded",
    desc52: "Min {n} HPT",
    desc53:
      "Withdrawals are not available within 24 hours after the successful change.",
    desc54: "The maximum withdrawal amount is {n} HPT",
    desc55: "{n} withdrawal",
    desc56: "Withdrawal available soon",
    desc57: "No Assets",

    desc58: "Transfer",
    desc59:
      "These assets can only be supported to transfer to Daoversal platform only.",
    desc60: "Receiver's UID must be in digits numbers. ",
    desc61:
      "When transferring, please verify that the receiver's UID is correct. Once the transfer is completed, the assets cannot be revoked.",
    desc62: "To Platform",
    desc63: "Receiver UID",
    desc64: "To Username",
    desc65: "To Email",
    desc66: "Transfer Record",
    desc67: "Type",
    desc68: "Receiver's UID",
    desc69: "Quantity",
    desc70: "Transfer details",
    desc71: "Confirm",
    desc72: "Enter Daoversal UID",
    desc73: "Enter the transfer amount",
    desc74: "Transfer",
    desc75: "External transfer-daoversal",
    desc76:
      "One Daoversal account can only receive DU credits once from HV / HN each.",
    desc77: "Balance insufficient",
    desc78: "{n} Transfer",
    desc79:
      "For your account security, set up Google Authenticator before withdrawing funds.",
    desc80:
      "For your account security, set up Google Authenticator before transferring funds.",
  },
};
export default en;
