<template>
  <div class="user-con">
    <van-popover v-model:show="show" placement="bottom-end">
      <template #reference>
        <img class="icon-user" src="../../../public/img/icon_user.svg" />
      </template>
      <div class="content">
        <p>{{ $t("header.desc1") }}</p>
        <div class="list">
          <div class="item" @click="handleGoogleChange" v-if="isSetGoogle">
            <img class="default" src="../../../public/img/icon_google.svg" />
            <img class="on" src="../../../public/img/icon_google_on.svg" />
            <span>{{ $t("header.desc2") }}</span>
          </div>
          <div class="item" @click="handleGoogleSet" v-else>
            <img class="default" src="../../../public/img/icon_google.svg" />
            <img class="on" src="../../../public/img/icon_google_on.svg" />
            <span>{{ $t("header.desc8") }}</span>
          </div>
          <div class="item" @click="handlePwdChange">
            <img class="default" src="../../../public/img/icon_password.svg" />
            <img class="on" src="../../../public/img/icon_password_on.svg" />
            <span>{{ $t("header.desc3") }}</span>
          </div>
          <div class="item" @click="handleHelpPath">
            <img class="default" src="../../../public/img/icon_help.svg" />
            <img class="on" src="../../../public/img/icon_help_on.svg" />
            <span>{{ $t("header.desc9") }}</span>
          </div>
          <div class="item" @click="handleLogOut">
            <img class="default" src="../../../public/img/icon_logOut.svg" />
            <img class="on" src="../../../public/img/icon_logOut_on.svg" />
            <span>{{ $t("header.desc4") }}</span>
          </div>
        </div>
      </div>
    </van-popover>
    <logoutPopup v-model:show="logOutShow" />
    <ChangePwdPopup v-model:show="changePwdShow" />
  </div>
</template>

<script setup>
import logoutPopup from "./logoutPopup";
import ChangePwdPopup from "./ChangePwdPopup";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const isSetGoogle = computed(() => store.state.userData.verifyGoogle);
const router = useRouter();
let show = ref(false);

function handleGoogleChange() {
  show.value = false;
  router.push("/google-change");
}
const changePwdShow = ref(false);
function handlePwdChange() {
  show.value = false;
  changePwdShow.value = !changePwdShow.value;
}

let logOutShow = ref(false);
function handleLogOut() {
  show.value = false;
  logOutShow.value = !logOutShow.value;
}
function handleHelpPath() {
  window.open("https://hypercommunity.zendesk.com/hc/en-us", "_blank");
}

function handleGoogleSet() {
  router.push("/google");
  show.value = false;
}
</script>

<style scoped lang="less">
.user-con {
  margin-right: 0.4rem;
}

.icon-user {
  width: 0.24rem;
  height: 0.26rem;
  object-fit: contain;
  cursor: pointer;
}

.content {
  padding: 0.22rem 0.16rem;

  p {
    color: #bdbdbd;
    font-family: Poppins;
    font-size: 0.12rem;
    line-height: 1.2em;
    font-style: normal;
    font-weight: 400;
  }

  .list {
    display: flex;
    flex-direction: column;

    .item {
      margin-top: 0.08rem;
      display: flex;
      align-items: center;
      padding: 0.08rem;
      box-sizing: border-box;
      border-radius: 0.04rem;
      cursor: pointer;
      transition: all 0.3s linear;

      &:hover {
        background: #f1f1f1;

        .default {
          display: none;
        }

        .on {
          display: block;
        }

        span {
          color: #8644ff;
        }
      }

      .default {
        display: block;
      }

      .on {
        display: none;
      }

      img {
        margin-right: 0.04rem;
        width: 0.2rem;
        height: 0.2rem;
        object-fit: contain;
      }

      span {
        color: #000;
        font-family: Poppins;
        font-size: 0.14rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4em;
        transition: all 0.3s linear;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .user-con {
    margin-right: 0.14rem;
  }

  .icon-user {
    width: 0.18rem;
    height: 0.18rem;
  }
}
</style>
