import config from '../config'
const { post, get, URL, Toast, $t } = config
export default {
    async sendVerifyCode(data) {
        await post(URL.common.sendVerifyCode, data)
        Toast({
            message: $t('common.desc8'),
            duration: 1500
        });
        let timer = setTimeout(() => {
            timer = null
            Toast.clear()
        }, 1500)
    },
}