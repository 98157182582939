<template>
  <van-popup
    class="popup-transparent auth-popup"
    :overlay="true"
    close-on-click-overlay
    v-model:show="_show"
  >
    <div class="popup-con">
      <div class="content">
        {{ content }}
      </div>

      <div class="btn-con">
        <div class="btn" @click="_show = false">{{ $t("common.desc2") }}</div>
        <div class="btn" @click="handleBingGoogleCodePath">
          {{ $t("common.desc19") }}
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
const content = computed(() => store.state.googleCodeBindToastContent);
const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["update:show"]);
const _show = computed({
  get: () => {
    return store.state.googleCodeBindToastShow;
  },
  set: (e) => {
    store.commit("SET_GOOGLE_CODE_BIND_TOAST_SHOW", e);
  },
});

async function handleBingGoogleCodePath() {
  router.push("/google/set");
  _show.value = false;
}
</script>
<style>
.auth-popup {
  width: 6.54rem;
  max-width: 80%;
}
</style>
<style scoped lang="less">
.popup-con {
  width: 100%;
  border-radius: 0.28rem;
  padding: 0.6rem;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;

  .content {
    padding-bottom: 0.2rem;
    color: #000;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: PingFang SC;
    font-size: 0.18rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.55em;
  }

  .btn-con {
    margin-top: 0.4rem;
    width: 100%;
    display: flex;

    .btn {
      margin-left: 0.06rem;
      flex: 1;
      height: 0.6rem;
      background: #8644ff;
      color: #fff;
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:first-child {
        border-radius: 0.43rem 0 0 0.43rem;
      }

      &:last-child {
        border-radius: 0 0.43rem 0.43rem 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .popup-con {
    padding: 0.3rem 0.2rem;
    border-radius: 0.14rem;

    .title {
      font-size: 0.16rem;
      margin-bottom: 0.2rem;
    }

    .content {
      padding-bottom: 0;
      font-size: 0.12rem;
    }

    .btn-con {
      margin-top: 0.24rem;

      .btn {
        margin-left: 0.03rem;
        height: 0.44rem;
        font-size: 0.13rem;
        line-height: 1.2em;
      }
    }
  }
}
</style>
