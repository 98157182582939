let googleData = {};
try {
  if (localStorage.googleData) {
    googleData = JSON.parse(localStorage.googleData);
  }
} catch (error) {}
export default {
  state: {
    googleData,
    googleCodeBindToastShow: false,
    googleCodeBindToastContent: "",
  },
  mutations: {
    SET_GOOGLE_DATA(state, data) {
      state.googleData = Object.assign(state.googleData, {
        ...data,
      });
      localStorage.googleData = JSON.stringify(state.googleData);
    },
    SET_GOOGLE_CODE_BIND_TOAST_SHOW(state, bool) {
      state.googleCodeBindToastShow = bool;
    },
    SET_GOOGLE_CODE_BIND_TOAST_CONTENT(state, content) {
      state.googleCodeBindToastContent = content;
    },
  },

  actions: {},
  getters: {},
};
