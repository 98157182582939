import Cryptojs from "crypto-js";
import md5 from "js-md5";
export default {
  hasFromUrl() {
    if (localStorage.fromUrl) {
      return true;
    }
    return false;
  },
  goFromUrl() {
    //判断是否为其他网站授权
    if (localStorage.fromUrl) {
      const token = localStorage.token;
      const href = `${localStorage.fromUrl}?access_token=${token}`;
      localStorage.removeItem("fromUrl");
      window.location.href = href;
    }
  },
  isMobile() {
    let flag =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    return flag;
  },
  truncateString(str, len = 4) {
    str = str.toString();
    const first = str.slice(0, len);
    const last = str.slice(-len);
    return `${first}...${last}`;
  },

  formatDate(timestamp, format = "YYYY-MM-DD hh:mm:ss") {
    try {
      const date = new Date(timestamp);
      const YY = date.getFullYear();
      const MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
      const DD = (date.getDate() < 10 ? "0" : "") + date.getDate();
      const hh = (date.getHours() < 10 ? "0" : "") + date.getHours();
      const mm = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
      const ss = (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();

      return format
        .replace("YYYY", YY)
        .replace("MM", MM)
        .replace("DD", DD)
        .replace("hh", hh)
        .replace("mm", mm)
        .replace("ss", ss);
    } catch (error) {
      return timestamp;
    }
  },
  sign1(obj) {
    var keys = [];
    for (let key in obj) {
      keys.push(key);
    }
    keys.sort();
    let strTemp = "";
    for (let k in keys) {
      let value = obj[keys[k]];
      if ("string" != typeof value) {
        value = JSON.stringify(value);
      }
      strTemp += value;
    }

    return md5(strTemp.repeat(5));
  },
  mdpassword(data) {
    return md5(data);
  },
  toFixed(num, k) {
    return parseFloat(num).toFixed(k);
  },
  //  加密方法
  aes(word) {
    const key = Cryptojs.enc.Utf8.parse("4063EF9C087ECCFCF0ACDD22C127F445");
    const iv = Cryptojs.enc.Utf8.parse("0123456789ABCDEF");
    let srcs = Cryptojs.enc.Utf8.parse(word);
    let encrypted = Cryptojs.AES.encrypt(srcs, key, {
      iv: iv,
      mode: Cryptojs.mode.CBC,
      padding: Cryptojs.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  //  解密方法
  //  data：要解密的字符串
  //  key：加密因子
  //  iv：偏移量
  ads(data) {
    const key = Cryptojs.enc.Utf8.parse("4063EF9C087ECCFCF0ACDD22C127F445"); // 加密因子
    const iv = Cryptojs.enc.Utf8.parse("0123456789ABCDEF"); // 偏移量
    let encryptedHexStr = Cryptojs.enc.Hex.parse(data);

    let str = Cryptojs.enc.Base64.stringify(encryptedHexStr);

    let decrypted = Cryptojs.AES.decrypt(str, key, {
      iv: iv,
      mode: Cryptojs.mode.CBC, // AES加密模式
      padding: Cryptojs.pad.Pkcs7, // 填充:  pkcs7 paddin
    });
    return decrypted.toString(Cryptojs.enc.Utf8).toString();
  },
  sign(obj) {
    let apiSecret = "test";
    if (process.env.NODE_ENV == "production") {
      apiSecret = "DOE5JyF6FagDrAl";
    }
    var keys = [];
    for (let key in obj) {
      keys.push(key);
    }

    keys.sort();
    let strTemp = "";
    for (let k in keys) {
      let value = obj[keys[k]];
      if ("string" != typeof value) {
        value = JSON.stringify(value);
      }
      strTemp += keys[k] + "=" + value + "&";
    }
    strTemp = strTemp.replace(/&$/, ""); //去掉最后的逗号
    return Cryptojs.HmacSHA256(strTemp, apiSecret).toString();
  },
  GetRandomNum(min, max) {
    var range = max - min;
    var rand = Math.random();
    return min + Math.round(rand * range);
  },
  numToFixed(number, len = 4) {
    try {
      const strNumber = number.toString();
      const decimalIndex = strNumber.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPart = strNumber
          .slice(decimalIndex + 1, decimalIndex + len + 1)
          .padEnd(len, "0");
        const _num = strNumber.slice(0, decimalIndex + 1) + decimalPart;
        if (Number(_num) === 0) {
          return Number(_num).toFixed(len);
        }
        return _num;
      } else {
        return Number(number).toFixed(len); // 如果整数部分没有小数点，则使用toFixed方法
      }
    } catch (error) {
      return number;
    }
  },
  passwordIsValid(value) {
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    const regex = /^.{8,20}$/;
    return regex.test(value);
  },
};
