export default {
  projectTime(val) {
    if(val != undefined){
    if (val.length == 10) val = val + '000';
    let newVal = new Date(parseInt(val)) + ''
    let arr = newVal.split(' ')
    // 返回示例 Aug 13,2021 19:03:14
    return arr[1] + ' ' + arr[2] + ',' + arr[3] + ' ' + arr[4]
    }
  },
  formatDate(date) {  
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    // 返回示例 2021-08-13 19:03:14
    return YY + MM + DD +" "+hh + mm + ss;
  },
  formatDay(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return MM + DD;
  },
  formatYMD(date) {
    var date = new Date(parseInt(date));
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD;
  },
  formatYMDH(date){
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h =date.getHours()
    h = h < 10 ? ('0' + h) : h
    let M =date.getMinutes()
    M = M < 10 ? ('0' + M) : M
    let s =date.getSeconds()
    s = s < 10 ? ('0' + s) : s;
    return  y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;
   
  },
  i18date(date) {
    if (!date) return date
    if(localStorage.getItem('language')=='en'){
      return this.projectTime(date);
    }else{
      return this.formatDate(date)
    }
  }

}