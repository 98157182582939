<template>
    <div class="code-con">
        <div class="inp-con" v-for="(item, index) in verificationCode" :key="index">

            <input class="inp" :class="{ active: Boolean(verificationCode[index]) }" ref="inputBox" type="text"
                v-model="verificationCode[index]" :maxlength="6" @input="handleInput(index, $event)"
                @keydown="handleKeyDown(index, $event)" :style="{ caretColor: isFocused(index) ? 'black' : 'transparent' }"
                :disabled="shouldDisableInput(index)" />
        </div>
    </div>
</template>
 

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
const props = defineProps({
    codeValue: Array
})
const emit = defineEmits(['update:codeValue'])

const verificationCode = computed({
    get() {
        return props.codeValue
    },
    set(e) {
        emit('update:codeValue', e.substring(0, 6))
    }
})
const currentFocusIndex = ref(0)
const inputBox = ref(null)
function handleInput(index, event) {
    // 只有上一个输入框有值时，才允许转移到下一个输入框
    if (index > 0 && !verificationCode.value[index - 1]) {
        return;
    }
    // 移除输入中的空格
    const inputValue = event.target.value.replace(/\s/g, '');
    event.target.value = inputValue;
    const regex = /^[0-9]*$/; // 正则表达式，匹配正整数
    if (!regex.test(event.target.value)) {
        // 如果输入不是正整数，则清除非法字符
        event.target.value = '';
        return
    }
    if (!event.target.value) {
        verificationCode.value[index] = ''
        return
    }

    // 输入后将焦点转移到下一个输入框
    if (index < verificationCode.value.length - 1) {
        currentFocusIndex.value = index + 1;
        inputBox.value[currentFocusIndex.value].focus();
    }
    if (event.target.value.length > 1) {
        // 处理粘贴的内容，依次填充到后面的输入框
        const pasteContent = event.target.value.substring(index);
        for (let i = index; i < verificationCode.value.length; i++) {
            verificationCode.value[i] = pasteContent[i - index] || '';
        }

        setCurrentFocus()
    }
}

function handleKeyDown(index, event) {

    // 输入框为空时按退格键将焦点转移到上一个输入框，并清空当前输入框
    if (event.key === 'Backspace') {
        if (!verificationCode.value[currentFocusIndex.value]) {
            currentFocusIndex.value = currentFocusIndex.value - 1;
            if (currentFocusIndex.value >= 0) {
                verificationCode.value[currentFocusIndex.value] = '';
                inputBox.value[currentFocusIndex.value].focus();
            }
        }
    }
}

function isFocused(index) {
    // 判断当前输入框是否获得焦点
    return index === currentFocusIndex.value
}

function shouldDisableInput(index) {
    // 当上一个输入框没有值时，禁用当前输入框
    return index > 0 && !verificationCode.value[index - 1];
}
function setCurrentFocus() {
    nextTick(() => {
        try {
            // 如果所有输入框都有值，则焦点移至最后一个输入框，否则移至第一个没有值的输入框
            if (verificationCode.value.every((code) => code !== '')) {
                currentFocusIndex.value = verificationCode.value.length;
            } else {
                currentFocusIndex.value = verificationCode.value.findIndex((code) => code === '');
            }
            inputBox.value[currentFocusIndex.value].focus();
        } catch (error) {

        }
    })
}
onMounted(() => {
    setCurrentFocus()
    document.addEventListener('click', () => {
        const activeElement = document.activeElement;
        // 判断当前获得焦点的元素是否为输入框
        if (!activeElement || (activeElement.tagName !== 'INPUT' && activeElement.tagName !== 'TEXTAREA')) {
            // 将焦点设置到第一个输入框
            inputBox.value[currentFocusIndex.value].focus();
        }
    });
})
</script>

 
<style lang="less" scoped>
.code-con {
    margin-top: 0.6rem;
    width: calc(100% + 0.3rem);
    margin-left: -0.15rem;
    display: flex;

    .inp-con {
        min-width: 0;
        flex: 1;
        padding: 0 0.15rem;
        box-sizing: border-box;

        .inp {
            width: 100%;
            height: 0.55rem;
            text-align: center;
            line-height: 0.55rem;
            border-bottom: 1px solid#EDEDED;
            margin-right: 0.1rem;
            color: #8644FF;
            font-size: 0.18rem;

            &.active {
                border-bottom: 1px solid #8644FF;
            }
        }
    }
}



@media screen and (max-width: 768px) {
    .code-con {
        width: calc(100% + 0.14rem);
        margin-left: -0.07rem;
        margin-top: 0.65rem;

        .inp-con {
            padding: 0 0.07rem;

            .inp {
                height: 0.42rem;
            }
        }

        .code-item {
            height: 0.42rem;
        }

        .code-item-con {}

        .input-code {
            height: 0.42rem;
        }
    }
}
</style>