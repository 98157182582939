<template>
  <page-scroll v-if="isRouterAlive">
    <router-view v-slot="{ Component }">
      <!-- <keep-alive> -->
      <component :is="Component" />
      <!-- </keep-alive>     -->
    </router-view>
  </page-scroll>
</template>
<script setup>
import PageScroll from "./components/PageScroll";
import { ref, provide, nextTick } from "vue";

let isRouterAlive = ref(true);
function reload() {
  isRouterAlive.value = false;
  nextTick(function () {
    isRouterAlive.value = true;
  });
}

provide("reload", reload);
</script>
<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
