<template>
    <div class="form">
        <PageNav :text="$t('login.desc16')" />
        <div class="item">
            <div class="email-title">
                {{ $t('login.desc17') }}
                <span>{{ email }}</span>
            </div>
        </div>
        <VerificationInp v-model:codeValue="codeValue" />
        <div class="resend-con">
            <div class="resend" @click="getVerifyCode" v-if="time === 0">{{ $t('login.desc21') }}</div>
            <van-count-down :time="time" format="ss s" @finish="time = 0" v-else />

        </div>
        <div class="btn" :class="{ on: code.length >= 6 }" @click="handleConfirm">{{ $t('login.desc22') }}
        </div>
    </div>
</template>

<script setup>
import VerificationInp from './components/VerificationInp'
import { ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import api from '@/api'
import { computed } from 'vue';
const { sendVerifyCode } = api
const store = useStore()
const { proxy } = getCurrentInstance()
const emit = defineEmits(['confirm'])
const props = defineProps({
    email: String,
    type: [String, Number]
})

let time = ref(0)
async function getVerifyCode() {
    if (time.value > 0) return
    time.value = 120 * 1000
    try {
        await sendVerifyCode({
            account: props.email,
            type: Number(props.type)
        })
    } catch (error) {
        time.value = 0
    }
}


const codeValue = ref(['', '', '', '', '', ''])
const code = computed(() => {
    return codeValue.value.reduce((prev, cur) => {
        return `${prev}${cur}`
    }, '')
})
function handleConfirm() {
    if (code.value.length < 6) {
        proxy.$toast(proxy.$t('common.desc5'))
        return
    }
    emit('confirm', code.value)
}

</script>

<style lang="less" scoped>
.title {
    padding: 0.4rem;
    box-sizing: border-box;
    border-radius: 0.16rem;
    background: #FFF;
    box-shadow: 0 0.04rem 0.28rem 0 rgba(0, 0, 0, 0.05);
    color: #382187;
    text-align: center;
    font-family: Poppins;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5em;
    /* 100% */
}

.form {
    margin: 0 auto;
    width: 7.04rem;

    .item {
        margin-top: 0.2rem;
        display: flex;
        flex-direction: column;

        .email {
            &-title {
                color: #382187;
                font-family: Poppins;
                font-size: 0.18rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.4em;
                // display: flex;
                // flex-direction: column;

                span {
                    color: #8644FF;
                }
            }
        }
    }

    .resend-con {
        margin-top: 0.36rem;
        display: flex;
        justify-content: center;

        .resend {
            font-family: Poppins;
            font-size: 0.16rem;
            line-height: 1.5em;
            font-style: normal;
            font-weight: 400;
            color: #8C8C8C;
            transition: all 0.3s linear;
            cursor: pointer;

            &:hover {
                color: #8644FF;
            }
        }

        ::v-deep .van-count-down {
            font-family: Poppins;
            font-size: 0.16rem;
            line-height: 1.5em;
            font-style: normal;
            font-weight: 400;
            color: #8644FF;
        }
    }

    .btn {
        margin-top: 0.72rem;
        width: 100%;
        height: 0.6rem;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 0.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 1rem;
        background: #E2E2E2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.on {
            color: #fff;
            background: #8644FF;
        }

    }


}

@media screen and (max-width: 768px) {
    .title {
        padding: 0.2rem 0.5rem;
        font-size: 0.18rem;
        line-height: 0.24rem;
        border-radius: 0.08rem;
        font-weight: 700;
    }

    .form {
        width: 100%;
        padding: 0 0.12rem;

        .item {
            margin-top: 0.1rem;

            .email {
                &-title {
                    font-size: 0.14rem;


                }
            }
        }

        .resend-con {
            margin-top: 0.24rem;

            .resend {
                font-size: 0.12rem;
            }

            ::v-deep .van-count-down {
                font-size: 0.12rem;
            }
        }

        .btn {
            margin-top: 0.5rem;
            height: 0.48rem;
            font-size: 0.13rem;

        }

        .authorized {
            margin-top: 0.16rem;

            img {
                margin-right: 0.08rem;
                width: 0.24rem;
                height: 0.24rem;
            }

            span {
                font-size: 0.14rem;
            }
        }
    }
}
</style>