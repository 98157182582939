
import { createI18n } from 'vue-i18n'
import { Locale } from 'vant'
import zhTw from "vant/lib/locale/lang/zh-TW";
import enUS from 'vant/es/locale/lang/en-US';
import messages from './index'

if (localStorage.getItem('language') == null) {
  localStorage.setItem('language', 'en_US');
  Locale.use("en-US", enUS);
} else {
  Locale.use("zh-TW", zhTw);
}
let lang = localStorage.getItem('language');
const i18n = createI18n({
  locale: lang,
  messages,
  silentTranslationWarn: true
})
export default i18n
