
import { createApp } from 'vue'
import i18n from './public/lang/i18n'
import App from './App.vue'
import { vant } from "./plugins/vant";
import { Lazyload } from 'vant';
import glComponent from './components';

import store from './store';
import router from './router';
import directive from './utils/directive'
import md5 from 'js-md5';
import URL from "./api/config/url";
import common from "./utils/common.js";
import method from "./utils/method.js";
import { post, get } from "./utils/http";
import './public/css/common.css';
import 'animate.css';
import "./public/css/font.css";
import "./public/js/rem.js";


const app = createApp(App)

app.config.globalProperties = {
  URL,
  common,
  method,
  $md5: md5,
  // $i18n: i18n,
  $t: i18n.global.t,
  $get: get,
  $post: post,
}
vant(app);
directive(app)
app.use(store)
  .use(router)
  .use(i18n)
  .use(Lazyload)
  .use(glComponent)
  .mount('#app')
