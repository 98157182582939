<template>
    <van-popup class="popup-transparent change-pwd-popup" :overlay="true" close-on-click-overlay v-model:show="_show">
        <div class="popup-con">
            <div class="title">
                {{ $t('header.desc3') }}
            </div>
            <div class="content">
                <div class="tips">
                    {{ $t('login.desc24') }}
                </div>
                <div class="item">
                    <div class="name">{{ $t('login.desc47') }}</div>
                    <div class="inp-con">
                        <input class="inp" type="text" :placeholder="$t('login.desc50')" maxlength="20" v-no-space
                            v-model="password">
                    </div>
                </div>
                <div class="item">
                    <div class="name">{{ $t('login.desc48') }}</div>
                    <div class="inp-con">
                        <input class="inp" type="text" :placeholder="$t('login.desc13')" maxlength="20" v-no-space
                            v-model="newPassword">
                    </div>
                </div>
                <div class="item">
                    <div class="name">{{ $t('login.desc49') }}</div>
                    <div class="inp-con">
                        <input class="inp" type="text" :placeholder="$t('login.desc52')" maxlength="20" v-no-space
                            v-model="againPassword">
                    </div>
                </div>
            </div>

            <div class="btn-con">
                <div class="btn" @click="_show = false">{{ $t('common.desc2') }}</div>
                <div class="btn" @click="handleConfirm">{{ $t('common.desc3') }}</div>
            </div>
        </div>
    </van-popup>
</template>

<script setup >
import { ref, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex';
import api from '@/api'
const { updatePassword } = api
const { proxy } = getCurrentInstance()
const { passwordIsValid } = proxy.common
const store = useStore()
const props = defineProps({
    show: Boolean
})

const emit = defineEmits(["update:show"])
const _show = computed({
    get: () => {
        return props.show;
    },
    set: (e) => {
        emit("update:show", e);
    },
});
const password = ref('')
const newPassword = ref('')
const againPassword = ref('')
async function handleConfirm() {
    if (!password.value) {
        proxy.$toast(proxy.$t('login.desc50'))
        return
    } else if (!passwordIsValid(newPassword.value)) {
        proxy.$toast(proxy.$t('login.desc13'))
        return
    } else if (!againPassword.value) {
        proxy.$toast(proxy.$t('login.desc52'))
        return
    } else if (newPassword.value !== againPassword.value) {
        proxy.$toast(proxy.$t('common.desc6'))
        return
    }
    proxy.$toast.loading({ forbidClick: true, })
    await updatePassword({
        password: proxy.$md5(password.value),
        newPassword: proxy.$md5(newPassword.value)
    })
    password.value = ''
    newPassword.value = ''
    againPassword.value = ''
    _show.value = false
    proxy.$toast.clear()
    proxy.$toast(proxy.$t('common.desc12'))
}

function handleInp(e) {

}
</script>
<style>
.change-pwd-popup {
    width: 6.54rem;
    max-width: 80%;
}
</style>
<style scoped lang='less'>
.popup-con {
    width: 100%;
    border-radius: 0.28rem;
    padding: 0.6rem;
    box-sizing: border-box;
    background-color: #FFF;
    overflow: hidden;

    .title {
        margin-bottom: 0.45rem;
        color: #000;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2em;
    }

    .content {
        .tips {
            color: #382187;
            font-family: Poppins;
            font-size: 0.18rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.2rem;
        }

        .item {
            margin-top: 0.3rem;
            display: flex;
            flex-direction: column;

            .name {
                margin-bottom: 0.12rem;
                color: #382187;
                font-family: Poppins;
                font-size: 0.18rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.4em;
            }

            .inp-con {
                display: flex;

                .inp {
                    width: 0;
                    flex: 1;
                    height: 0.6rem;
                    border-radius: 0.04rem;
                    border: 0.5px solid #EDEDED;
                    background: #FFF;
                    padding: 0 0.2rem;
                    display: flex;
                    align-items: center;
                    font-family: Poppins;
                    font-size: 0.16rem;
                    font-style: normal;
                    font-weight: 400;
                    color: #8644FF;


                    &::placeholder {
                        color: #CACACA;
                    }
                }
            }
        }
    }

    .btn-con {
        margin-top: 0.4rem;
        width: 100%;
        display: flex;

        .btn {
            margin-left: 0.06rem;
            flex: 1;
            height: 0.6rem;
            background: #8644FF;
            color: #FFF;
            text-align: center;
            font-family: PingFang SC;
            font-size: 0.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:first-child {
                border-radius: 0.43rem 0 0 0.43rem;
            }

            &:last-child {
                border-radius: 0 0.43rem 0.43rem 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .popup-con {
        padding: 0.3rem 0.2rem;
        border-radius: 0.14rem;

        .title {
            font-size: 0.16rem;
            margin-bottom: 0.2rem;
        }

        .content {
            .tips {
                font-size: 0.12rem;
                line-height: 0.16rem;
            }

            .item {
                margin-top: 0.2rem;

                .name {
                    margin-bottom: 0.1rem;
                    font-size: 0.13rem;
                }

                .inp-con {

                    .inp {
                        padding: 0 0.12rem;
                        height: 0.44rem;
                        font-size: 0.12rem;
                    }

                    .send-con {
                        font-size: 0.12rem;

                        ::v-deep .van-count-down {
                            font-size: 0.12rem;
                        }
                    }
                }
            }
        }

        .btn-con {
            margin-top: 0.24rem;

            .btn {
                margin-left: 0.03rem;
                height: 0.44rem;
                font-size: 0.13rem;
            }
        }
    }
}
</style>
