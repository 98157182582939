const zh = {
  title: {
    asset: "資產",
    login: "登錄",
    authorizedSet: "安全驗證",
    emailVerification: "郵箱驗證碼",
    forgot: "忘記密碼",
    googleSet: "設置Google驗證碼",
    googleChange: "更改 Google 身份驗證器",
  },
  header: {
    desc1: "個人中心",
    desc2: "更改Google驗證碼",
    desc3: "更改密碼",
    desc4: "退出登錄",
    desc5: "語言",
    desc6: "English",
    desc7: "中文繁體",
    desc8: "設置Google驗證碼",
    desc9: "帮助中心",
  },
  common: {
    desc1: "複製成功",
    desc2: "取消",
    desc3: "确认",
    desc4: "網絡異常，連接超時...",
    desc5: "請填寫完整的驗證碼",
    desc6: "兩個密碼不一致",
    desc7: "設置成功",
    desc8: "郵箱驗證碼發送成功",
    desc9: "退出成功",
    desc10: "HV 用戶",
    desc11: "HN 用戶",
    desc12: "修改成功",
    desc13: "提幣申請成功",
    desc14: "没有更多了",
    desc15: "正在加載...",
    desc16: "登錄成功",
    desc17: "驗證碼錯誤",
    desc18: "轉帳申請成功",
    desc19: "前往綁定",
  },
  login: {
    desc1: "超級社區資產管理平台",
    desc2: "您好，請登錄",
    desc3: "用戶名/電子郵箱",
    desc4: "請輸入用戶名/電子郵箱",
    desc5: "密碼",
    desc6: "請輸入登錄密碼",
    desc7: "忘記密碼？",
    desc8: "確認",
    desc9: "HyperVerse新用戶首次登入",
    desc10: "安全驗證",
    desc11:
      "為了保護您的賬戶安全，您首次登錄平台時需要重置密碼。 密碼修改成功後，下次可以直接使用用戶名和新設置的密碼登錄。",
    desc12: "設置新密碼",
    desc13: "8-20位大小字母+數字組成",
    desc14: "確認密碼",
    desc15: "請再次輸入密碼",
    desc16: "郵箱驗證碼",
    desc17: "郵箱驗證碼已發送至：",
    desc18: "請輸入郵箱驗證碼",
    desc19: "發送驗證碼",
    desc21: "重新發送",
    desc22: "确认",
    desc23: "忘記密碼",
    desc24: "提示：重置密碼後，24小時內無法提取資產",
    desc25: "下一步",
    desc26: "電子郵箱",
    desc27: `請從應用商店下載'Google Authenticator'`,
    desc28: "掃描或複制密鑰",
    desc29: "掃描二維碼與穀歌驗證器綁定",
    desc30: "輸入Google驗證碼完成驗證",
    desc31: "Google驗證碼",
    desc32: "請輸入Google驗證碼",
    desc33: "請輸入{n}的6位驗證碼",
    desc34: "提示",
    desc35: `1. 如果您無法掃描二維碼，您可以使用以下密鑰添加您的帳戶：`,
    desc36: `2. 請不要刪除該賬戶，否則您可能無法進行賬戶操作。`,
    desc37: "提示：更改成功後24小時內無法提現",
    desc38: "輸入郵箱驗證碼",
    desc39: "舊的 Google 驗證",
    desc40: "請輸入舊 Google 驗證碼",
    desc41: "請輸入舊 Google 驗證的 6 位驗證碼",
    desc42: "使用“Google認證”掃描下方二維碼",
    desc43:
      "請記錄密鑰並將其保存在安全的地方， 您可以使用此密鑰恢復您的 Google 身份驗證",
    desc44: "輸入Google驗證碼完成驗證",
    desc45: "請輸入Google驗證的6位驗證碼",
    desc46: "確定要退出嗎？",
    desc47: "當前密碼",
    desc48: "新密碼",
    desc49: "確認新密碼",
    desc50: "請輸入當前密碼",
    desc51: "8-20位大小字母+數字組成",
    desc52: "請再次輸入新密碼",
    desc53: "Daoversal 應用授權登錄",
    desc54: "授權 Daoversal 獲取 UID、電子郵箱、用戶名和其他信息來為您服務",
    desc55: "取消",
    desc56: "授權登錄",
    desc57:
      "當前輸入的HyperVerse賬號尚未登錄HyperCommunity平台，請先登錄HyperVerse平台授權後再操作",
    desc58: "前往授權登錄",
    desc59: "切换账号",
    desc60: "輸入舊 Google 驗證碼",
    desc61:'您当前输入的HyperVerse账号为首次登录HyperCommunity平台，为了保护你的账号安全请先进行安全验证',
    desc62:'取消',
    desc63:'前往验证',
    desc64:'为了账号安全性，HyperVerse用户必须通过邮箱验证才可成功登录HyperCommunity ',
    desc65:'HyperVerse 用户名',
    desc66:'HyperVerse  邮箱',
    desc67:'设置登录密码',
    desc68:'请在首次登录时重置密码以增强安全性。 使用新密码和您的用户名以便将来登录 HyperCommunity。',
    desc69:'设置新密码',
    desc70:'8-20位大小字母+數字組成',
    desc71:'确认密码',
    desc72:'确认新密码',
    desc73:'验证HyperVerse 账号',
    desc74:'首次登录HyperCommunity 平台的用户可以通过输入HyperVerse账号并通过邮箱验证登录HyperCommunity ',
    desc75:'输入HyperVerse用户名',
    desc76:'HyperVerse用户名不存在',
    desc77:'輸入的帳號已經在 hyper-Community登入過，請直接用帳號密碼登入'

  },
  asset: {
    desc1: "UID",
    desc2: "用戶名",
    desc3: "郵箱",
    desc4: "最近登錄",
    desc5: "IP 地址",
    desc6: "來源",
    desc7: "總資產(HPT)",
    desc8: "待釋放",
    desc9: "幣種",
    desc10: "總計",
    desc11: "可用",
    desc12: "提幣凍結",
    desc13: "操作",
    desc14: "提幣",
    desc15: "提現歷史",
    desc16: "提幣",
    desc17: "幣種",
    desc18: "鏈名稱",
    desc19: "地址",
    desc20: "輸入提幣地址",
    desc21: "金額",
    desc22: "輸入提現金額",
    desc23: "全部",
    desc24: "可用餘額：",
    desc25: "費用",
    desc26: "到賬金額",
    desc27: "提幣",
    desc28: "請務必輸入正確的地址，避免資產丟失",
    desc29: "最低提幣金額：{n} HPT",
    desc30:
      "當您的賬戶安全策略變更或密碼修改時，我們可能會對提現情況進行人工審核，並通過電話或電子郵箱與您聯繫",
    desc31: "請確保您的計算機和瀏覽器安全，防止信息被篡改或洩露",
    desc32: "法幣購買的數字資產24小時內禁止提現",
    desc33: "USDT暫不支持智能合約提現，請您理解",
    desc34: "訂單號",
    desc35: "時間",
    desc36: "提幣地址",
    desc37: "交易ID",
    desc38: "令牌",
    desc39: "提幣金額",
    desc40: "主網",
    desc41: "手續費",
    desc42: "狀態",
    desc43: "無相關記錄",
    desc44: "輸入6位郵箱驗證碼",
    desc45: "Google驗證碼",
    desc46: "輸入 6 位 Google 驗證碼",
    desc47: "待釋放的資產不可進行其他操作，稍後更新資產使用計劃",
    desc48: "待審核",
    desc49: "提幣中",
    desc50: "提幣成功",
    desc51: "已退款",
    desc52: "最低提現金額為{n} HPT",
    desc53: "重置密碼後，24小時內無法提取資產",
    desc54: "最高提現金額為{n} HPT",
    desc55: "{n} 提现",
    desc56: "即将可以提现",
    desc57: "暫無資產",

    desc58: "外部转账",
    desc59: "只能把当前资产划转到daoversal平台。",
    desc60: "收款用户的ID为daoversal用户的ID，是一串数字。",
    desc61: "转移时请仔细核对收款用户ID是否正确，一旦转移成功,资产不可撤回。",
    desc62: "转出平台",
    desc63: "输入收款用户ID",
    desc64: "收款用户账号",
    desc65: "收款用户邮箱",
    desc66: "转账记录",
    desc67: "类型",
    desc68: "收款用户ID",
    desc69: "數量",
    desc70: "转出详情",
    desc71: "確定",
    desc72: "输入daoversal平台收款用户ID",
    desc73: "輸入转账金額",
    desc74: "转账",
    desc75: "外部转账-daoversal",
    desc76: "一個Daoversal 帳號 只能從HV/HN 的帳號各轉一次待釋放。",
    desc77: "余额不足",
    desc78: "{n} 轉出",
    desc79: "為了確保你的資產安全，提幣時請先綁定谷歌",
    desc80: "為了確保你的資產安全，轉帳時請先綁定谷歌",
  },
};
export default zh;
