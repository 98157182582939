let loginData = {}
let forgotData = {}
let userData = {}
try {
    if (localStorage.loginData) {
        loginData = JSON.parse(localStorage.loginData)
    }
} catch (error) { }
try {
    if (localStorage.forgotData) {
        forgotData = JSON.parse(localStorage.forgotData)
    }
} catch (error) { }
try {
    if (localStorage.userData) {
        userData = JSON.parse(localStorage.userData)
    }
} catch (error) {

}
export default {
    state: {
        loginData,
        forgotData,
        userData,
        authorizedShow: false
    },
    mutations: {
        SET_AUTHORIZED_SHOW(state, show) {
            state.authorizedShow = show
        },
        SET_USER_DATA(state, data) {
            state.userData = Object.assign(state.userData, data)
            localStorage.userData = JSON.stringify(state.userData)
            localStorage.token = state.userData.token

        },
        SET_LOGIN_DATA(state, data) {
            state.loginData = Object.assign(state.loginData, {
                ...data
            })
            localStorage.loginData = JSON.stringify(state.loginData)
        },
        SET_FORGOT_DATA(state, data) {
            state.forgotData = Object.assign(state.forgotData, {
                ...data
            })
            localStorage.forgotData = JSON.stringify(state.forgotData)
        },
        SET_LOG_OUT(stata) {
            stata.forgotData = {}
            stata.loginData = {}
            stata.userData = {}
            stata.wHistoryData = {}
            localStorage.removeItem('forgotData')
            localStorage.removeItem('loginData')
            localStorage.removeItem('userData')
            localStorage.removeItem('token')
            localStorage.removeItem('wHistoryData')
        }
    },

    actions: {


    },
    getters: {
        isLogin: state => {
            try {
                return Object.keys(state.userData).length > 0 && state.userData.hasPassword
            } catch (error) {
                return false
            }
        }
    },
}
