/**
vant组件引用
**/
import {
  Button, Popup, Icon, Image as VanImage, Loading,
  List, Popover, Toast, Dialog, CountDown
} from "vant";



import 'vant/lib/index.css'
import '@vant/touch-emulator';
// 通过循环去按需引入
export const vant = (app) => {
  [Button, Popup, Icon, VanImage, Loading, List, Popover, Toast, Dialog, CountDown
  ].forEach((e) => {
    app.use(e);
  });
};
