//langs/index.js
import enLocale from './en'
import zhLocale from './zh'
export default {
  en_US: {
    ...enLocale,
  },
  zh_TW: {
    ...zhLocale
  }
}
