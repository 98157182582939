import config from '../config'
const { post, get, URL, Toast, $t } = config
export default {
    getGoogleurl() {
        return post(URL.google.getGoogleurl)
    },
    setGoogle(data) {
        return post(URL.google.setGoogle, data)
    },
    updateGoogle(data) {
        return post(URL.google.updateGoogle, data)
    },

}